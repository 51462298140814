export class ShowSpinner {
  static readonly type = '[Spinner] Show';
}

export class HideSpinner {
  static readonly type = '[Spinner] Hide';
}

export class ResetSpinner {
  static readonly type = '[Spinner] Reset';
}
