import {Injectable, OnDestroy} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {SpinnerState} from './spinner.state';
import {map, merge, Observable, Subject} from 'rxjs';
import {skipWhile, takeUntil} from 'rxjs/operators';
import {HideSpinner, ResetSpinner, ShowSpinner} from './spinner.actions';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService implements OnDestroy {
  @Select(SpinnerState.isActive) isActive$: Observable<boolean>;

  public readonly isInactive$: Observable<boolean>;

  private isActiveState: boolean;
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly store: Store) {
    this.isActive$.pipe(takeUntil(this.destroy$)).subscribe(isActive => {
      this.isActiveState = isActive;
    });

    this.isInactive$ = this.isActive$.pipe(map(isActive => !isActive));
  }

  get isActive() {
    return this.isActiveState;
  }

  show() {
    this.store.dispatch(ShowSpinner);
  }

  hide() {
    this.store.dispatch(HideSpinner);
  }

  clear() {
    this.store.dispatch(ResetSpinner);
  }

  toggleBy(...togglers: Observable<boolean>[]) {
    return merge<boolean[]>(
      ...togglers.map(toggler =>
        toggler.pipe(
          skipWhile(loading => !loading),
          takeUntil(this.destroy$)
        )
      )
    ).subscribe(loading => {
      if (loading) {
        this.show();
      } else {
        this.hide();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
