import {HttpContext, HttpContextToken} from '@angular/common/http';

export const SILENCE_ERROR = new HttpContextToken<boolean>(() => false);
export const silentError =
  (silent = true) =>
  (context = new HttpContext()) =>
    context.set(SILENCE_ERROR, silent);
export const skipError = silentError();

export const SILENCE_RESPONSE_STATUSES = new HttpContextToken<number[]>(() => null);
export const silentResponseStatuses =
  (responseStatuses: number[]) =>
  (context = new HttpContext()) =>
    context.set(SILENCE_RESPONSE_STATUSES, responseStatuses);
export const skipResponseStatuses = (responseStatuses: number[]) => silentResponseStatuses(responseStatuses);

export const THROW_ORIGINAL_ERROR = new HttpContextToken<boolean>(() => false);
export const throwOriginalError =
  () =>
  (context = new HttpContext()) =>
    context.set(THROW_ORIGINAL_ERROR, true);
export const rethrowTheOriginalError = throwOriginalError();

export interface ErrorCodeZeroContextData {
  networkCheckHttpResponse?: number;
  retryAttempted: boolean;
  retryHttpResponse?: number;
}
export const ERROR_CODE_ZERO = new HttpContextToken<ErrorCodeZeroContextData>(() => null);
