import {HttpContext, HttpContextToken, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SpinnerService} from '@matchsource/spinner';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

const SKIP_SPINNER = new HttpContextToken<boolean>(() => false);
export const useSpinner =
  (use = true) =>
  (context = new HttpContext()) =>
    context.set(SKIP_SPINNER, use);
export const skipSpinner = useSpinner();

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  reqCounter = 0;

  constructor(private readonly spinnerService: SpinnerService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (request.context.get(SKIP_SPINNER)) {
      return next.handle(request);
    }

    this.setLoading(true);

    return next.handle(request).pipe(finalize(() => this.setLoading(false)));
  }

  private setLoading(loading: boolean): void {
    if (loading) {
      this.reqCounter++;
      if (this.reqCounter === 1) {
        this.spinnerService.show();
      }
    } else {
      this.reqCounter--;
      if (this.reqCounter === 0) {
        this.spinnerService.hide();
      }
    }
  }
}
