import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {HideSpinner, ResetSpinner, ShowSpinner} from './spinner.actions';

type SpinnerStateModel = number;

@State<SpinnerStateModel>({
  name: 'spinner',
  defaults: 0,
})
@Injectable()
export class SpinnerState {
  @Selector([SpinnerState])
  static isActive(state: SpinnerStateModel) {
    return state > 0;
  }

  @Action(ShowSpinner)
  show({setState, getState}: StateContext<SpinnerStateModel>) {
    const counter = getState();
    setState(counter + 1);
  }

  @Action(HideSpinner)
  hide({setState, getState}: StateContext<SpinnerStateModel>) {
    const counter = getState();
    setState(Math.max(0, counter - 1));
  }

  @Action(ResetSpinner)
  reset({setState}: StateContext<SpinnerStateModel>) {
    setState(0);
  }
}
